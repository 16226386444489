import styled from 'styled-components';
import { COLORS } from "../../css/constants";

export const ContactContainer = styled.div`
    padding: 7.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: ${COLORS.lightgrey};

    @media screen and (max-width: 1230px) {
        grid-template-columns: repeat(1,1fr);
        padding: clamp(0.5rem, 5%, 7.5rem);
        padding-top: 80px;
    }
`;

export const LetsTalk = styled.div`
    text-align: left;
    align-items: left;
    margin-right: 50px;

    @media screen and (max-width: 1230px) {
        margin-right: 0px;
        padding-bottom: clamp(0.5rem, 3vw, 2rem);

        p, h2{
          text-align: center;
        }

    }
`;

export const H2 = styled.h2`
    font-size: clamp(3rem, 4vw, 4rem);
    text-align: left;
`;

export const P1 = styled.p`
    font-size: clamp(1rem, 2.5vw, 1.6rem);
    text-align: left;
    line-height: normal;

`;


export const StyledInlineErrorMessage = styled.div`
  background-color: #ffd6d6;
  color: rgb(120, 27, 0);
  padding: 0.75rem 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 75%;

  @media screen and (max-width: 1230px) {
    width: 100%;
    }
`;

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative;
  color: #3A0CA3;

`;

export const StyledCheckbox = styled.label`
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
  display: block;
  font-size: 1rem;
  width: 75%;
`;

export const InputCheckbox = styled.input.attrs({ type: 'checkbox' })`
  transform: scale(1.5);
  vertical-align: middle;
  position: relative;
  margin: 0.3rem 0.5rem 0.3rem 0.5rem;
  
`;

export const Input = styled.input`
  background-color: white;
  border: 1px solid #3A0CA3;
  transition: border-color 500ms ease-out;
  flex-direction: column;
  border-radius: 4px;
  font-size: 1rem;
  min-height: 3.125rem;
  font-style: normal;
  font-weight: 400;
  display: block;
  width: 75%;
  padding-left: 0.8rem;

  @media screen and (max-width: 1230px) {
    width: 100%;
    }

    &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }

  ;`

export const Textarea = styled.textarea`
  padding-left: 0.4rem;
  background-color: white;
  border: 1px solid #3A0CA3;
  transition: border-color 500ms ease;
  flex-direction: column;
  border-radius: 4px;
  font-size: 1rem;
  min-height: 3.125rem;
  font-style: normal;
  font-weight: 400;
  display: block;
  width: 75%;
  resize: none;

  @media screen and (max-width: 1230px) {
    width: 100%;
    }

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px,
      rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  };`

export const Form = styled.form`
  width: 100%;
  display: block;
  text-align: left;

  @media screen and (max-width: 1230px) {
    display: inline-block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    }
`;

export const Button = styled.button`
  background-color: #3A0CA3;
  color: #ffff !important;
  display: block;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  width: 75%;
  border: none;
  border-radius: 4px;

  @media screen and (max-width: 1230px) {
        width: 100%;
    }

  &:active,
  &:focus,
  &:hover {
    background: #1760ff;
    transition: 0.3s ease-out;
    cursor: pointer;
    color: #000;
  }

  &:disabled {
    cursor: pointer;
    background-color: rgb(163, 168, 173);
    box-shadow: none;
    color: rgb(255, 255, 255) !important;

    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }
`;

export const FormWrapper = styled.div`
    display: block;
    text-align: center;
`;

