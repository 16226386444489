import styled from 'styled-components';
import ImgBg from '../../images/starry-night-1920.jpg';


export const HeroContainer = styled.div`
    background: linear-gradient(to right,rgba(0,0,0,0.7),
                rgba(0,0,0,0.1)), url(${ImgBg});
    height: 100vh;
    background-position: center;
    background-size: cover;
`;

export const HeroContent = styled.div`
    height: calc(100vh - 80px);
    max-height: 100%;
    padding: 0rem calc((100vw - 1300px) /2);
`;

export const HeroItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    max-height: 100%;
    padding: 0 2rem;
    width: 650px;
    color: #fff;
    text-transform: uppercase;
    line-height: 1;
    font-weight: bold;

    @media screen and (max-width: 650px) {
        width: 100%;
    }

    @media screen and (max-width: 850px) and (orientation: landscape) {
        padding-top: 50px;
    }
`;

export const HeroH1 = styled.h1`
    font-size: clamp(2.3rem, 3vw, 3.5rem);
    color: #fff;

    @media screen and (max-width: 650px) {
        text-align: center;
    }
    @media screen and (max-width: 850px) and (orientation: landscape) {
        margin-bottom: 0;
        font-size: clamp(1.7rem, 3vw, 3.5rem);
    }
`;

export const HeroBtn = styled.button`
    font-size: clamp(1.5rem, 2.5vw, 2.5rem);
    padding: 1rem 4rem;
    border: none;
    background: #8854FF;
    color: #fff;

    @media screen and (max-width: 850px) and (orientation: landscape) {
        display: none;
    }

&:hover {
    background: #1760ff;
    transition: 0.5s ease-out;
    cursor: pointer;
    color: #fff;
}

    @media screen and (max-width: 650px) {
        margin: 0 auto;
    }
`;