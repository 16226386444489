import React from 'react';

import {
    FooterContainer,
    FooterWrap,
    Company
} from './FooterElements'
const Footer = () => {

    return (
        <FooterContainer>
            <FooterWrap>
                <Company>© 2021 Tara IT AB, Org. nr. 559313-5121</Company>
            </FooterWrap>
        </FooterContainer>
    );
}

export default Footer;