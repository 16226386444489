import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import LogoBlackText from "../../images/logo-text-color-black.svg";
import LogoWhiteText from "../../images/logo-text-color-white.svg";
import { COLORS } from "../../css/constants";


import {
  NavDiv,
  NavContainer,
  Menu,
  MenuItem,
  MenuLink,
  NavLogo,
  MobileIcon
} from "./NavElements"

const Nav = ({toggle}) => {
  const [navClass, setNavClass] = useState("none")

  useEffect(() => {
    document.addEventListener("scroll", toggleNavColor)
  }, [])

  const toggleNavColor = () => {
    if (window.pageYOffset > 10) {
      setNavClass("scrolled")
    } else {
      setNavClass("top")
    }
  }

  return (
    <>
    <NavContainer className={navClass} active={navClass}>
      <NavDiv>
        <NavLogo to="/">
          <img src={navClass === "scrolled" ? LogoBlackText : LogoWhiteText} alt="Tara IT Logo" />
        </NavLogo>
        <MobileIcon onClick={toggle}>
          <FaBars color={navClass === "scrolled" ? COLORS.darkpurple : "white" } />
        </MobileIcon>
        <Menu>
          <MenuItem >
            <MenuLink to="/">Home</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="#offerings">Offerings</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to="#contact">Contact</MenuLink>
          </MenuItem>
        </Menu>
      </NavDiv>
    </NavContainer>
    </>
  )
}

export default Nav
