import styled from 'styled-components'
import { COLORS } from "../../css/constants";

export const QuoteContainer = styled.div`
display: flex;
    width: 100%;
    padding: 1rem calc((100vw - 1300px) /2);
    background: ${ COLORS.white };
    height: auto;
`;

export const QuoteWrapper = styled.div`
    
    flex-wrap: wrap;
    justify-content: center;
    padding: 5rem;
    margin: 0 auto;
    @media screen and (max-width: 650px) {
        padding: 2rem;
    }
`;

export const QuoteHeading = styled.h3`
    font-size: clamp(1.5rem, 4vw, 2rem);
    text-align: center;
    margin-bottom: 2rem;
`;

export const QuoteP = styled.p`
    font-size: clamp(1rem, 2.5vw, 1.6rem);
    line-height: normal;
    text-align: center;


`;