import styled from "styled-components";
import { Link } from 'gatsby';

export const NavDiv = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
`;

export const NavContainer = styled.nav`
    position: fixed;
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 1.15rem;
    z-index: 100;
    top: 0;
    width: 100%;
    padding: 0rem calc((100vw - 1300px) /2);
    
    &.scrolled {
        background-color: white;
        transition: 500ms;
    }
    &.top {
        background-color: transparent;
        transition: 500ms;
    }

    &.scrolled li {
        //border-bottom: ${props => props.active ? "2px solid black" : "none"};
    }

    &.scrolled a {
        color: black;
    }

    &.scrolled.active {
        //border-bottom: 2px solid black;
    }

    @media screen and (max-width: 700px) {
        transition: 0.8s all ease;
    }

    
`;

export const MobileIcon = styled.div`
    display: none;
    
    @media screen and (max-width: 700px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 90%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }

    @media screen and (max-width: 850px) and (orientation: landscape) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 90%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`;

export const Menu = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 700px) {
        display: none;
    }

    @media screen and (max-width: 850px) and (orientation: landscape) {
        display: none;
    }
`;

export const MenuItem = styled.li`
    margin: 1rem 1rem 0 1rem;
    height: 100%;
    &.active {
        font-weight: bold;
    }
    cursor: pointer;
`;

export const MenuLink = styled(Link)`
    text-align: center;
    text-decoration: none;
    color: white;
    padding: 1rem 0.5rem 1rem 0.5rem;
    display: flex;

`;

export const NavLogo = styled(Link)`
 
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;

    img {
        width: 180px;
        margin-bottom: 0;
        margin-left: 0.5rem;
    }
`;


