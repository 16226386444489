import styled from 'styled-components'
import { COLORS } from '../../css/constants'

export const FillerContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: 650px) {
        grid-template-columns: repeat(1,1fr);
        div:nth-of-type(1) {order: 1;}
        div:nth-of-type(2) {order: 2;}
        div:nth-of-type(3) {order: 4;}
        div:nth-of-type(4) {order: 3;}   
    }
    
`;

export const FillerTextbox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 25rem;
    padding: 2.5rem;
    background-color: ${COLORS.lightgrey};

`;

export const FillerImg = styled.div`
 

`;

export const FillerP = styled.p`
    font-size: clamp(1rem, 2.5vw, 1.6rem);
    margin-bottom: 2rem;
    line-height: normal;
    text-align: center;
`;

export const FillerH3 = styled.h3`
    text-align: center;
    font-size: clamp(1.5rem, 4vw, 2rem);
    margin-bottom: 2rem;
`;