import React from 'react';
import {
    QuoteContainer,
    QuoteHeading,
    QuoteP,
    QuoteWrapper
} from './QuoteElements'

const Quote = () => {
    return (
        <QuoteContainer>
            <QuoteWrapper>
                <QuoteHeading data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
                    "Tara IT is the modern IT consultancy - adapting to our customers needs, not making our customers adapt to us."
                </QuoteHeading>
                <QuoteP data-sal="slide-up" data-sal-delay="350" data-sal-easing="ease">
                    Tara IT is a Swedish IT consultancy, building applications for our customers based on the latest technologies. Tara was founded by an ex-IBM:er with extensive experience from working as a consultant. We are builders at heart with the ambition to deliver high quality technical solutions to you - our customers.
                </QuoteP>
            </QuoteWrapper>
        </QuoteContainer>
    )
}

export default Quote