import styled from 'styled-components';

export const FooterContainer = styled.footer`
    background-color: #857c94;
`;

export const FooterWrap = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 100%;
  margin: 0 auto;
`;


export const Company = styled.div`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-weight: bold;
`;