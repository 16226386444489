import * as React from "react"
import {useState} from "react"

import Hero from "../components/Hero"
import Quote from "../components/Quote";
import Nav from "../components/Nav"
import Filler from "../components/Filler"
import Contact from "../components/Contact";
import Offerings from "../components/Offerings";
import { Helmet } from 'react-helmet';

import "../css/index.css";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";

const IndexPage = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Helmet>
        <title>Tara IT</title>
      </Helmet>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Nav toggle={toggle} />
      <Hero />
      <Quote />
      <Filler />
      <Offerings />
      <Contact />
      <Footer />
    </>
  )
}

export default IndexPage