import React from 'react';
import { Formik, useField } from 'formik';
import * as Yup from 'yup'
import { COLORS } from "../../css/constants";

import {
    Input,
    Button,
    Textarea,
    ContactContainer,
    StyledLabel,
    StyledInlineErrorMessage,
    Form,
    LetsTalk,
    H2,
    P1,
    FormWrapper,
    StyledCheckbox,
    InputCheckbox
}
    from './ContactElements'
import axios from 'axios';

const CustomCheckBox = ({ children, ...props }) => {
    const [field, meta] = useField(props, 'checkbox');

    return (<>
        <StyledCheckbox>
            <InputCheckbox type="checkbox" {...field} {...props} />
            {children}
        </StyledCheckbox>
        {meta.touched && meta.error ? (
            <StyledInlineErrorMessage>{meta.error}</StyledInlineErrorMessage>
        ) : null}

    </>
    )
}
const CustomTextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <>
            <StyledLabel htmlFor={props.id || props.name}>{label}
                <Input {...field} {...props} />
                {meta.touched && meta.error ? (
                    <StyledInlineErrorMessage>{meta.error}</StyledInlineErrorMessage>
                ) : null}
            </StyledLabel>

        </>
    );
};

const CustomTextAreaField = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <>
            <StyledLabel htmlFor={props.id || props.name}>{label}
                <Textarea {...field} {...props} />
                {meta.touched && meta.error ? (
                    <StyledInlineErrorMessage>{meta.error}</StyledInlineErrorMessage>
                ) : null}
            </StyledLabel>
        </>
    );
};


const Contact = () => {

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    return (


        <ContactContainer id="contact">

            <LetsTalk data-sal="slide-up" data-sal-duration="400" data-sal-easing="ease">
                <H2>Let's talk</H2>
                <P1>Want to start your next cool project, see what we can do for you and your organization or just want to say Hi?</P1>
                <P1>Fill in the form and we will get back to you ASAP! You can also reach us on email via <a style={{ "color": COLORS.darkpurple }} href="mailto:hi@tarait.com">hi@tarait.se</a></P1>
                <P1>We are looking forward to hearing from you!</P1>
                <P1>// The Tara IT team</P1>
            </LetsTalk>

            <FormWrapper data-sal="slide-up" data-sal-duration="400"  data-sal-easing="ease">
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        phone: '',
                        acceptedTerms: false,
                        message: '',
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string()
                            .min(2, 'Too short!')
                            .max(50, 'Too long!')
                            .required('Required'),
                        email: Yup.string()
                            .email('Invalid email')
                            .required('Required'),
                        phone: Yup.string()
                            .matches(phoneRegExp, 'Enter a valid phone number'),
                        message: Yup.string()
                            .required('Required')
                            .max(200)
                            .min(5),
                        acceptedTerms: Yup.boolean()
                            .required('Required')
                            .oneOf([true], 'You must accept the terms and conditons'),
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) => {

                        axios({
                            method: "POST",
                            url: "https://33jaz13wt7.execute-api.eu-west-1.amazonaws.com/live/contact",
                            data: values
                        })
                            .then(response => {
                                setSubmitting(false);
                                resetForm();
                            })
                            .catch(error => {
                                setSubmitting(false);
                                resetForm();
                            });
                    }}
                >

                    {props => (

                        <Form onSubmit={props.handleSubmit}>
                            <CustomTextField label="Name*" name="name" type="text" placeholder="Your name" />
                            <CustomTextField label="Email*" name="email" type="email" placeholder="Your email" />
                            <CustomTextField label="Phone" name="phone" type="text" placeholder="Your phone number" />
                            <CustomTextAreaField label="Message*" name="message" rows="6" type="textarea" control="textarea" placeholder="Your message" />
                            <CustomCheckBox name="acceptedTerms">I have read and agree to the terms of <a href="/privacy" target="_blank" rel="noopener noreferrer">Tara IT Privacy Policy.*</a></CustomCheckBox>
                            <Button disabled={!props.isValid || props.isSubmitting} type="submit">{props.isSubmitting ? 'Loading...' : 'Send message'}</Button>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        </ContactContainer>

    )
}

export default Contact