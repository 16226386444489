import React from 'react';

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import {
    FillerContainer,
    FillerTextbox,
    FillerImg,
    FillerP,
    FillerH3
} from './FillerElements'


const Filler = () => {
    const data = useStaticQuery(graphql`
    query Images {
        crane: file(relativePath: {eq: "crane.jpg"}) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        dock: file(relativePath: {eq: "dock.jpg"}) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }      
    `)
    return (
        <FillerContainer>
            <FillerTextbox data-sal="slide-up" data-sal-duration="600" data-sal-delay="200" data-sal-easing="ease">
                <FillerH3>Any industry, any time.</FillerH3>
                <FillerP>No matter what industry you are operating in - we have the solutions for you. Tara IT has experience in a wide variety of industries so we can help your company at any time. Get in touch to find out more about what Tara can do for you!</FillerP>
            </FillerTextbox>
            {/* <FillerImg img={img1} /> */}

            <FillerImg data-sal="slide-up" data-sal-duration="600" data-sal-delay="200" data-sal-easing="ease">
                <Img fluid={data.crane.childImageSharp.fluid} style={{height: '100%'}} />
            </FillerImg>
            <FillerImg data-sal="slide-up" data-sal-duration="600" data-sal-delay="200" data-sal-easing="ease">
                <Img fluid={data.dock.childImageSharp.fluid} style={{height: '100%'}} />
            </FillerImg>
            {/* <FillerImg img={img2} />*/}
            <FillerTextbox data-sal="slide-up" data-sal-duration="600" data-sal-delay="200" data-sal-easing="ease">
                <FillerH3>The Tara way</FillerH3>
                <FillerP>The Tara way of working is your way of working. We’re agile by nature and help you discover and explore the needs of your organization, and then help you throughout the entire project lifecycle.</FillerP>
            </FillerTextbox>
        </FillerContainer>
    )
}

export default Filler;