import React from 'react'
import { navigate } from "gatsby"


import {
    HeroContainer, HeroContent, HeroItems, HeroH1, HeroBtn
} from './HeroElements'

const Hero = () => {
    return (
        
            <HeroContainer>
                <HeroContent>
                    <HeroItems>
                        <HeroH1>Your journey starts with Tara and ends in the Cloud.</HeroH1>
                        <HeroBtn onClick={() => {navigate("#contact")}}>Contact</HeroBtn>
                    </HeroItems>
                </HeroContent>
            </HeroContainer >
    )
}

export default Hero;