import styled from 'styled-components'
import { COLORS } from "../../css/constants";

export const OfferingsContainer = styled.div`
    margin-top: auto;
    background-color: ${COLORS.white};
    padding: 3rem 2rem 3rem 2rem;

`;

export const OfferingsHeading = styled.h3`
    text-align: center;
    font-size: clamp(1.5rem, 4vw, 2rem);
    margin: 26px;
    margin-top: 30px;
`;

export const OfferingsWrapper = styled.div`
    display: grid;
    padding: 2rem;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;

    @media screen and (min-width: 1600px) {
        max-width: 1200px;
        margin: 0 auto;
    }

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 1rem;
    }
    
`;
export const OfferingCard = styled.div`
    display: flex;
    padding: 1rem;
    flex-direction: column;

    h4{
        color: #8854FF;
        margin-top: 0.5rem;
        font-size: 1.4rem;
        text-align: center;
        margin-bottom: 0;
        text-transform: uppercase;
    }
`;

export const IconWrapper = styled.div`
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
`;

export const OfferingDesc = styled.div`
    padding: 1rem;
    p {
        line-height: normal;
        margin: 0px;
        text-align: center;
        font-size: 1.2rem;
    }
`;