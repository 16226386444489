import React from 'react';

import {
    BsFillCloudFill
} from "react-icons/bs"

import {
    MdWeb
} from "react-icons/md"

import {
    FaUserFriends
} from "react-icons/fa"

import {
    GoDeviceMobile
} from "react-icons/go"

import {
    IoBuild
} from "react-icons/io5"

import {
    OfferingsContainer,
    OfferingsHeading,
    OfferingsWrapper,
    OfferingCard,
    IconWrapper,
    OfferingDesc

} from './OfferingsElements'

import { COLORS } from '../../css/constants';

const Offerings = () => {
    return (
        <OfferingsContainer id="offerings">
            <OfferingsHeading data-sal="fade" data-sal-delay="300" data-sal-easing="ease">Our Offerings</OfferingsHeading>
            <OfferingsWrapper>
                <OfferingCard data-sal="fade" data-sal-duration="500" data-sal-delay="200" data-sal-easing="ease">
                    <IconWrapper>
                        <BsFillCloudFill color={ COLORS.darkpurple } size={"100"} />
                    </IconWrapper>
                    <h4>Cloud</h4>
                    <OfferingDesc>
                        <p>Tara operates in the Cloud by default. We help you in your cloud transformation journey. AWS, Azure or Google Cloud Platform? Doesn’t matter - we've got you covered!</p>
                    </OfferingDesc>
                </OfferingCard>
                <OfferingCard data-sal="fade" data-sal-duration="500" data-sal-delay="200" data-sal-easing="ease">
                    <IconWrapper>
                        <MdWeb color={ COLORS.darkpurple } size={"100"} display={"block"} />
                    </IconWrapper>
                    <h4>Web</h4>
                    <OfferingDesc>
                        <p>Need a new website? A dashboard? Tara operates on the very latest web technologies and can take full ownership of the process: from idea to production.</p>
                    </OfferingDesc>
                </OfferingCard>
                <OfferingCard data-sal="fade" data-sal-duration="500" data-sal-delay="200" data-sal-easing="ease">
                    <IconWrapper>
                        <GoDeviceMobile color={ COLORS.darkpurple } size={"100"} display={"block"} />
                    </IconWrapper>
                    <h4>Mobile</h4>
                    <OfferingDesc>
                        <p>Many businesses go mobile first - is it time for you to make the switch? We are here and ready to build your next app.</p>
                    </OfferingDesc>
                </OfferingCard>
                <OfferingCard data-sal="fade" data-sal-duration="500" data-sal-delay="200" data-sal-easing="ease">
                    <IconWrapper>
                        <FaUserFriends color={ COLORS.darkpurple } size={"100"} />
                    </IconWrapper>
                    <h4>Consulting</h4>
                    <OfferingDesc>
                        <p>Need some extra manpower for your team? Need help with project management? We are standing ready to jump in, no matter what your needs are!</p>
                    </OfferingDesc>
                </OfferingCard>
                <OfferingCard data-sal="fade" data-sal-duration="500" data-sal-delay="200" data-sal-easing="ease">
                    <IconWrapper>
                        <IoBuild color={ COLORS.darkpurple } size={"100"} display={"block"} />
                    </IconWrapper>
                    <h4>Custom</h4>
                    <OfferingDesc>
                        <p>Got any other ideas? Want to outsource your IT-department? Need help building the next cool thing? We are always up for a challenge - get in touch to see what we can do for you.</p>
                    </OfferingDesc>
                </OfferingCard>
                <OfferingCard data-sal="fade" data-sal-duration="500" data-sal-delay="200" data-sal-easing="ease">
                    <IconWrapper>
                        <MdWeb color={ COLORS.darkpurple } size={"100"} display={"block"} />
                    </IconWrapper>
                    <h4>TRIRIGA & MAXIMO</h4>
                    <OfferingDesc>
                        <p>Need help to integrate Tririga/Maximo with an external system? We'll even help you integrate your Tririga/Maximo with an custom app. Drop us an email, and we'll take it from there! </p>
                    </OfferingDesc>
                </OfferingCard>
            </OfferingsWrapper>
        </OfferingsContainer>
    )
}

export default Offerings;
